<template>
  <div
    @mouseleave="isHover = false"
    @mouseenter="isHover = !isHover"
    class="boxScheduleAdmin"
    :style="{
      padding: isMobile
        ? this.desc.typeModel === 'istirahat'
          ? 0
          : isHover
          ? 0
          : ''
        : this.desc.typeModel === 'istirahat' || isHover
        ? 0
        : '',
      border: isMobile
        ? this.desc.typeModel === 'istirahat'
          ? 0
          : isHover
          ? 0
          : ''
        : this.desc.typeModel === 'istirahat' || isHover
        ? 'none'
        : '',
    }"
    :key="n"
  >
    <a-modal
      :visible="modalEdit"
      @cancel="modalEdit = false"
      @ok="confirmEdit"
      ok-text="Edit"
      :confirm-loading="loadingConfirmEdit"
    >
      <h3 slot="title" style="line-height: 1.7rem">
        Are you sure to edit {{ dataGuru.nama }} schedule?
      </h3>
      <div>
        this schedule then will be added on each week's semester with fixed
        topics.
      </div>
    </a-modal>
    <a-modal
      :visible="modalConfirm"
      @cancel="confirmCancel"
      @ok="confirmAdd"
      ok-text="Add"
      :confirm-loading="loadingConfirmAdd"
    >
      <h3 slot="title" style="line-height: 1.7rem">
        Are you sure to add new schedule for {{ dataGuru.nama }}?
      </h3>
      <div>
        this schedule then will be added on each week's semester with fixed
        topics.
      </div>
    </a-modal>
    <a-modal
      :visible="visibleEditSlot"
      title="Edit slot"
      okText="Edit"
      @cancel="handleCancelEdit"
      @ok="handleOkEdit"
    >
      <a-form layout="vertical">
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="8">
              <a-form-item label="Class">
                <a-input :value="inputClassEdit" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Day">
                <a-select
                  :value="inputDayEdit"
                  size="large"
                  placeholder="Choose Day"
                  style="width: 100%; height: 48px"
                  @change="handleSelectDayEdit"
                >
                  <a-select-option
                    v-for="day in days"
                    :key="day"
                    :value="day"
                    >{{ day }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Subject">
                <a-input :value="inputSubjectEdit" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="8">
              <a-form-item label="Start">
                <a-select
                  size="large"
                  placeholder="Choose Start Time"
                  style="width: 100%; height: 48px"
                  @change="(value) => handleSelectStartEdit(value)"
                  :value="inputStartEdit"
                >
                  <template v-for="(time, i) in times">
                    <a-select-option
                      v-if="time.type !== 'istirahat'"
                      :key="i"
                      :value="time.position"
                      >{{ time.startAt }}</a-select-option
                    >
                  </template>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Class Hour">
                <!-- <a-select
                  :value="inputClassHourEdit"
                  size="large"
                  placeholder="Choose Class Hour"
                  style="width: 100%; height: 48px"
                  @change="handleSelectClassHourEdit"
                >
                  <a-select-option
                    v-for="n in classHoursEdit"
                    :key="n"
                    :value="n"
                    >{{ n }}</a-select-option
                  >
                </a-select> -->
                <a-input-number size="large" class="w-100" :max="classHoursEdit" @change="handleSelectClassHourEdit" :value="inputClassHourEdit" :min="1"></a-input-number>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="End">
                <a-select class="w-100" size="large" :value="endAtEdit.position" disabled>
                  <a-select-option v-for="time in times" :key="time.position" :value="time.position">{{time.endAt}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="24">
              <a-form-item label="Teacher">
                <a-select
                  size="large"
                  placeholder="Choose Teacher"
                  style="width: 100%; height: 48px"
                  @change="changeInputTeacherEdit"
                  :value="inputTeacherEdit"
                >
                  <a-select-option
                    v-for="guru in guruEdit"
                    :key="guru.id"
                    :value="guru.id"
                    >{{ guru.nama }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </a-form>
    </a-modal>
    <a-modal
      :visible="visible"
      title="Create new slot"
      okText="Create"
      @cancel="handleCancel"
      @ok="handleCreate"
      :okButtonProps="{
        props: {
          disabled: loadingAdd,
        },
      }"
      :cancelButtonProps="{
        props: {
          disabled: loadingAdd,
        },
      }"
    >
      <div
        v-if="loadingAdd"
        style="
          display: flex;
          justify-content: center;
          height: 30vh;
          align-items: center;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: var(--kit-color-primary)"
            spin
          />
        </a-spin>
      </div>
      <a-form layout="vertical" v-else>
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="24">
              <a-form-item label="Day">
                <a-input :value="day" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
        <a-input-group size="large">
          <a-row :gutter="8">
            <a-col :span="8">
              <a-form-item label="Start">
                <a-input :value="desc.jamMulai" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="Class Hour" required has-feedback>
                <!-- <a-select
                  size="large"
                  placeholder="Choose Class Hour"
                  style="width: 100%; height: 48px"
                  @change="handleSelectClassHour"
                  default-value="1"
                  :value="inputClassHour"
                >
                  <a-select-option
                    v-for="n in classHours"
                    :key="n"
                    :value="n"
                    >{{ n }}</a-select-option
                  >
                </a-select> -->
                <a-input-number class="w-100" size="large" :max="classHours" @change="handleSelectClassHour" :value="inputClassHour" :min="1"></a-input-number>
                <small
                  v-if="validationAdd.classHour"
                  style="position: relative; color: red"
                  >Class Hour is required!</small
                >
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="End">
                <a-input :value="endAt" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
        <a-input-group>
          <a-row :gutter="[8,0]">
            <a-col :span="12">
              <a-form-item required label="Class" has-feedback>
                <a-select
                  size="large"
                  placeholder="Select Class"
                  style="width: 100%"
                  @change="handleSelectClass"
                >
                  <a-select-option
                    v-for="item in kelas"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.level.nama }}-{{ item.simbol }}</a-select-option
                  >
                </a-select>
                <small
                  v-if="validationAdd.class"
                  style="position: relative; color: red"
                  >Class is required!</small
                >
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                required
                label="Subject"
                :style="{
                  marginBottom: validationAdd.subject ? '' : '19%',
                }"
              >
                <a-select
                  size="large"
                  placeholder="Select Subject"
                  style="width: 100%; height: 48px"
                  @change="handleSelectSubject"
                >
                  <a-select-option
                    v-for="item in mapel"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.nama }}</a-select-option
                  >
                </a-select>
                <small
                  v-if="validationAdd.subject"
                  style="position: relative; color: red"
                  >Subject is required!</small
                >
              </a-form-item>
            </a-col>
          </a-row>
        </a-input-group>
      </a-form>
    </a-modal>
    <div
      class="break"
      v-if="
        desc.typeModel === 'istirahat' ||
        (isMobile && (n === 5 || n === 10))
      "
    >
      <!-- break -->
    </div>
    <template v-else>
      <template v-if="slots.length > 0">
        <template>
          <template v-for="(slot, idxSlot) in slots">
            <template v-for="(session, idx) in slot.sesis">
              <div
                v-if="
                  (!isMobile &&
                    n % 5 === slot.hari &&
                    desc.position === slot.posisi) ||
                  (isMobile &&
                    dayInputMobile === slot.hari &&
                    n === slot.posisi)
                "
                class="boxSlot"
                @mouseenter="showIcon = true"
                @mouseleave="showIcon = false"
                :key="`${idxSlot} dan ${idx}`"
              >
                <a-row type="flex" align="middle">
                  <a-col :span="15">
                    <h3>{{ slot.kela.level.nama }}-{{ slot.kela.simbol }}</h3>
                    <div class="subH3">{{ slot.mata_pelajaran.nama }}</div>
                  </a-col>
                  <a-col :span="9" v-if="showIcon && !isRepeated">
                    <p
                      class="buttonSlot mb-2"
                      style="color: #1b55e3"
                      @click.prevent="
                        showModalEdit(
                          {
                            mapel: slot.mata_pelajaran,
                            kelas: slot.kela,
                            idSlot: slot.id,
                            posisi: slot.posisi,
                            jamPelajaran: slot.jam_pelajaran
                          }
                        )
                      "
                    >
                      <a-icon type="edit" />
                      <span class="textButtonSlot"> Edit</span>
                    </p>
                    <p
                      @click.prevent="deleteSlot(slot.id, slot)"
                      class="buttonSlot"
                      style="color: #ff0000"
                    >
                      <a-icon type="delete" />
                      <span class="textButtonSlot"> Delete </span>
                    </p>
                  </a-col>
                </a-row>
              </div>
            </template>
          </template>
        </template>
      </template>
      <transition name="slide-fadein-right" mode="out-in">
        <div
          v-if="isHover && isEmpty"
          class="hoverSlot"
          @click.prevent="showModal"
        >
          <a-icon
            class="animationPlus"
            type="plus-circle"
            style="font-size: 40px; color: white"
          />
        </div>
      </transition>
    </template>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['n', 'isEmpty', 'desc', 'slots', 'times', 'dataGuru', 'isMobile', 'dayInputMobile', 'modalVisible'],
  data() {
    return {
      topic: [],
      inputClass: null,
      inputSubject: null,
      inputClassHour: 1,
      isHover: false,
      showIcon: false,
      visible: false,
      visibleEditSlot: false,
      inputSubjectEdit: null,
      inputDayEdit: null,
      inputStartEdit: null,
      inputClassHourEdit: 1,
      inputTeacherEdit: null,
      inputClassEdit: null,
      currentEditSlot: null,
      positionEdit: null,
      idKelasEdit: null,
      kelas: [],
      mapel: [],
      guruEdit: [],
      jamSelesai: null,
      jamSelesaiEdit: null,
      days: ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat'],
      loadingAdd: false,
      loadingConfirmAdd: false,
      modalConfirm: false,
      modalEdit: false,
      loadingConfirmEdit: false,
      validationAdd: {
        class: false,
        subject: false,
        classHour: false,
      },
      idTahunAjaran: this.$route.params.id,
    }
  },
  methods: {
    confirmCancel() {
      this.modalConfirm = false
    },
    showModal() {
      this.visible = true
      this.loadingAdd = true
      this.$store.dispatch('admin/FETCH_CLASS_AND_SUBJECT_BY_SLOT', {
        idGuru: this.dataGuru.id,
        posisi: this.desc.position,
        hari: this.day,
        idTahunAjaran: this.idTahunAjaran,
      })
        .then(({ kelas, mapel }) => {
          // console.log(kelas)
          if (kelas.length === 0 || this.mapel === 0) {
            this.visible = false
            this.$notification.error({
              message: 'Sorry.',
              description:
                "It's either you don't have class yet on this semester or all class already have schedule on that time.",
            })
          } else {
            this.kelas = kelas
            this.mapel = mapel
            this.loadingAdd = false
            this.jamSelesai = this.desc.jamSelesai
            this.inputClassHour = 1
          }
        })
    },
    async validateClass(payload, status) {
      try {
        const data = status === 'edit' ? {
          id_kelas: this.idKelasEdit,
          posisi: this.endAtEdit.position,
          hari: this.day,
          jam_pelajaran: this.inputClassHourEdit,
          id_tahun_ajaran: this.idTahunAjaran,
          id_guru: this.dataGuru.id,
        } : {
          id_kelas: this.inputClass,
          posisi: this.desc.position,
          hari: this.day,
          jam_pelajaran: this.inputClassHour,
          id_guru: this.dataGuru.id,
          id_tahun_ajaran: this.idTahunAjaran,
        }
        const isValidate = await this.$store.dispatch('admin/VALIDATION_CLASS_SLOT', {
          ...data,
          ...payload,
        })
        return new Promise((resolve, reject) => {
          resolve(isValidate)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async handleSelectClassHour(value) {
      try {
        if (this.inputClass) {
          // const jamMapelSelanjutnya = moment(this.desc.jamMulai, 'HH:mm').add((this.desc.durasi) + addBreak, 'minutes').format('HH:mm')
          // this.inputClassHour = value
          const isValidate = await this.validateClass({ jam_pelajaran: value })
          if (isValidate) {
            // console.log(data)
            this.inputClassHour = value
          } else {
            this.inputClassHour = 1
            // console.log(this.inputClassHour)
            this.$notification.error({
              message: 'Error',
              description:
                'That class already has schedule on next class hour',
            })
          }
        } else {
          this.inputClassHour = value
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleCancel() {
      this.inputClass = null
      this.inputSubject = null
      this.inputClassHour = 1
      this.jamSelesai = moment(this.desc.jamMulai, 'HH:mm').add(this.desc.durasi * 1, 'minutes').format('HH:mm')
      this.visible = false
      this.validationAdd = {
        class: false,
        subject: false,
        classHour: false,
      }
    },
    async confirmAdd() {
      try {
        this.loadingConfirmAdd = true
        await this.$store.dispatch('admin/CREATE_SLOT', {
          slot: {
            tipe_model_jadwal: this.desc.tipe_model_jadwal,
            id_guru: this.dataGuru.id,
            id_mata_pelajaran: this.inputSubject,
            id_kelas: this.inputClass,
            hari: this.day,
            jam_mulai: this.desc.jamMulai,
            posisi: this.desc.position,
            jam_pelajaran: this.inputClassHour,
          },
          idTahunAjaran: this.idTahunAjaran,
        })
        this.loadingConfirmAdd = false
        this.modalConfirm = false
        this.$notification.success({
          message: 'Success',
          description:
            'Successfully add new schedule.',
        })
        this.$emit('fetchScheduleTeacher')
        this.visible = false
      } catch (err) {
        console.log(err)
        this.loadingConfirmAdd = false
        this.modalConfirm = false
        this.$notification.error({
          message: 'Failed',
          description:
            "Can't add slot yet. Pleas try again later.",
        })
        this.$emit('fetchScheduleTeacher')
      }
      // const data = await this.$store.dispatch('admin/FETCH_SCHEDULE_TEACHER', { idGuru: this.dataGuru.id, idTahunAjaran: this.idTahunAjaran })
      // setTimeout(() => {
      //   this.loadingConfirmAdd = false
      //   this.$notification.success({
      //     message: 'Success',
      //     description:
      //       'Successfully add new schedule.',
      //   })
      //   this.modalConfirm = false
      //   this.visible = false
      // }, 2000)
      // this.$emit('teacherSlotUpdate', data)
    },
    handleCreate() {
      if (!this.inputSubject || !this.inputClass || !this.inputClassHour) {
        this.validationAdd = {
          subject: this.inputSubject === null,
          class: this.inputClass === null,
          classHour: this.inputClassHour === null,
        }
        this.$notification.error({
          message: 'Error',
          description:
            'All field is required!',
        })
      } else {
        this.modalConfirm = true
      }
    },
    handleSelectSubject(value) {
      this.inputSubject = value
    },
    async handleSelectClass(value) {
      try {
        if (this.inputClassHour >= 2) {
          this.inputClass = value
          // console.log({
          //   tipeModelJadwal: this.desc.tipe_model_jadwal,
          //   jamMulai: jamMapelSelanjutnya,
          //   idKelas: this.inputClass,
          //   posisi: this.desc.position === 4 ? this.desc.position + 2 : this.desc.position + 1,
          //   hari: this.day,
          // })
          const isValidate = await this.validateClass()
          if (isValidate) {
            // console.log(data)
            this.inputClass = value
          } else {
            this.inputClassHour = 1
            this.$notification.error({
              message: 'Error',
              description:
                'That class already has schedule on next class hour',
            })
          }
        } else {
          this.inputClass = value
        }
      } catch (err) {
        console.log(err)
      }
    },
    // DELETE SLOT
    deleteSlot(idSlot, slot) {
      this.$confirm({
        title: 'Delete Schedule',
        content: (
          <div>
            <div>Are you sure to delete schedule {slot.mata_pelajaran.nama} on {slot.kela.level.nama}-{slot.kela.simbol} ( {this.day}, {this.desc.jamMulai} ) ?</div>
            <small style="color: red;">Once deleted, you can't bring the schedule back.</small>
          </div>
        ),
        onOk: async () => {
          try {
            await this.$store.dispatch('admin/DELETE_SLOT', {
              idSlot,
            })
            this.$emit('fetchScheduleTeacher')
            this.$notification.success({
              message: 'Success',
              description:
                'Successfully delete schedule.',
            })
          } catch (err) {
            console.log(err)
            this.$emit('fetchScheduleTeacher')
            this.$notification.error({
              message: 'Failed',
              description:
                "Can't delete slot yet.",
            })
          }
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
    // //
    // EDIT SLOT
    async validateEditSlot(payload, status = 'edit') {
      try {
        const status = {
          id_guru: this.inputTeacherEdit,
          jam_pelajaran: this.inputClassHourEdit,
          id_kelas: this.idKelasEdit,
          posisi: this.inputStartEdit,
          hari: this.day,
          id_tahun_ajaran: this.idTahunAjaran,
          id_slot: this.desc.id,
        }
        const isValidate = await this.$store.dispatch('admin/VALIDATE_EDIT_SLOT', {
          ...status,
          ...payload,
        })

        return new Promise((resolve) => resolve(isValidate))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async changeInputTeacherEdit(value) {
      try {
        const sameWithFirst = this.inputDayEdit === this.day && Number(this.inputStartEdit) === this.desc.position && Number(this.inputClassHourEdit) === this.desc.jamPelajaran && Number(value) === Number(this.dataGuru.id)
        if (sameWithFirst) {
          this.inputTeacherEdit = value
        } else {
          let isValidate = true
          isValidate = await this.validateEditSlot({ id_guru: value })
          if (isValidate) {
            isValidate = await this.validateClass({ id_guru: value }, 'edit')
            if (isValidate) this.inputTeacherEdit = value
            else {
              this.$notification.error({
                message: 'Error',
                description: 'The class already has schedule on that time',
              })
            }
          } else {
            this.$notification.error({
              message: 'Error',
              description: 'The teacher already has schedule on that time',
            })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    async handleSelectDayEdit(value) {
      try {
        const sameWithFirst = value === this.day && Number(this.inputStartEdit) === this.desc.position && Number(this.inputClassHourEdit) === this.desc.jamPelajaran && Number(this.inputTeacherEdit) === Number(this.dataGuru.id)
        // console.log(value, 'jgkdjk')
        if (sameWithFirst) {
          this.inputDayEdit = value
        } else {
          let isValidate = true
          isValidate = await this.validateEditSlot({ hari: value })
          if (isValidate) {
            isValidate = await this.validateClass({ hari: value }, 'edit')
            if (isValidate) this.inputDayEdit = value
            else {
              this.$notification.error({
                message: 'Error',
                description: 'The class already has schedule on that time',
              })
            }
          } else {
            this.$notification.error({
              message: 'Error',
              description: 'The teacher already has schedule on that time',
            })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    async handleSelectStartEdit(value) {
      try {
        const sameWithFirst = this.inputDayEdit === this.day && Number(value) === this.desc.position && Number(this.inputClassHourEdit) === this.desc.jamPelajaran && Number(this.inputTeacherEdit) === Number(this.dataGuru.id)
        if (sameWithFirst || (this.inputDayEdit === this.day && this.desc.jamPelajaran >= 2 && this.inputClassHourEdit === 1 && Number(value) === this.desc.position + 1)) {
          this.inputStartEdit = value
        } else {
          let isValidate = true
          isValidate = await this.validateEditSlot({ posisi: value })
          if (isValidate) {
            isValidate = await this.validateClass({ posisi: value }, 'edit')
            if (isValidate) this.inputStartEdit = value
            else {
              this.$notification.error({
                message: 'Error',
                description: 'The class already has schedule on that time',
              })
            }
          } else {
            this.$notification.error({
              message: 'Error',
              description: 'The teacher already has schedule on that time',
            })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    async handleSelectClassHourEdit(value) {
      try {
        const sameWithFirst = this.inputDayEdit === this.day && Number(value) === this.desc.position && Number(value) === this.desc.jamPelajaran && Number(this.inputTeacherEdit) === Number(this.dataGuru.id)

        if (sameWithFirst || (this.inputDayEdit === this.day && Number(this.inputStartEdit) === this.desc.position && value <= this.desc.jamPelajaran)) {
          this.inputClassHourEdit = value
        } else {
          let isValidate = true
          isValidate = await this.validateEditSlot({ jamPelajaran: value })
          if (isValidate) {
            isValidate = await this.validateClass({ jamPelajaran: value }, 'edit')
            if (isValidate) this.inputClassHourEdit = value
            else {
              this.$notification.error({
                message: 'Error',
                description: 'The class already has schedule on that time',
              })
            }
          } else {
            this.$notification.error({
              message: 'Error',
              description: 'The teacher already has schedule on that time',
            })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    async showModalEdit({ mapel, kelas, idSlot, posisi, jamPelajaran }) {
      this.currentEditSlot = idSlot
      const gurus = await this.$store.dispatch('admin/GET_TEACHER_BY_ID_MAPEL', { idMapel: mapel.id })
      this.guruEdit = gurus
      gurus.forEach(guru => {
        if (guru.id === parseInt(this.dataGuru.id)) {
          this.inputTeacherEdit = guru.id
        }
      })
      this.idKelasEdit = kelas.id
      this.inputClassEdit = `${kelas.level.nama}-${kelas.simbol}`
      this.inputSubjectEdit = mapel.nama
      this.inputStartEdit = posisi
      this.visibleEditSlot = true
      this.inputClassHourEdit = jamPelajaran
      this.positionEdit = posisi
      this.inputDayEdit = this.day
      this.jamSelesaiEdit = this.desc.jamSelesai
    },
    async confirmEdit() {
      try {
        const slot = {
          hari: this.inputDayEdit,
          id_guru: this.inputTeacherEdit,
          posisi: this.inputStartEdit,
          jam_pelajaran: this.inputClassHourEdit,
        }
        this.loadingConfirmEdit = true
        await this.$store.dispatch('admin/UPDATE_SLOT', {
          idSlot: this.currentEditSlot,
          slot,
        })

        this.$emit('fetchScheduleTeacher')
        setTimeout(() => {
          this.loadingConfirmEdit = false
          this.$notification.success({
            message: 'Success',
            description:
              'Successfully edit schedule.',
          })
          this.modalEdit = false
          this.visibleEditSlot = false
        }, 2000)
      } catch (err) {
        console.log(err)
        this.$notification.error({
          message: 'Error',
          description: "Can't update slot right now. Please try again later.",
        })
      }
    },
    handleOkEdit() {
      this.modalEdit = true
    },
    handleCancelEdit() {
      this.idKelasEdit = null
      this.inputSubjectEdit = null
      this.inputDayEdit = null
      this.inputStartEdit = null
      this.inputClassHourEdit = null
      this.inputTeacherEdit = null
      this.inputClassEdit = null
      this.currentEditSlot = null
      this.positionEdit = null
      this.jamSelesaiEdit = null
      this.visibleEditSlot = false
    },
    // //
  },
  computed: {
    isRepeated() {
      let isRepeated = false
      let slotList = this.slots.filter(slot => slot.id === this.desc.id)
      if (slotList.length > 1) {
        slotList = slotList.sort((a, b) => a.posisi - b.posisi)
        if (slotList[0].posisi !== this.desc.position) {
          isRepeated = true
        }
      }
      return isRepeated
    },
    classHours() {
      // console.log(this.times)
      if (this.times.length - (this.desc.position - 1) < 3) {
        return this.times.length - (this.desc.position - 1)
      } else {
        return 3
      }
    },
    classHoursEdit() {
      if (this.times.length - (Number(this.inputStartEdit) - 1) < 3) {
        return this.times.length - (Number(this.inputStartEdit) - 1)
      } else {
        return 3
      }
    },
    classes() {
      return this.$store.state.admin.classes
    },
    day() {
      switch (this.desc.day) {
        case 1:
          return 'Senin'
        case 2:
          return 'Selasa'
        case 3:
          return 'Rabu'
        case 4:
          return 'Kamis'
        case 0:
          return 'Jumat'
        default:
          return ''
      }
    },
    endAt() {
      if (this.desc.jamMulai) {
        if (this.inputClassHour === 1 || this.desc.position === this.times.length) {
          return this.desc.jamSelesai
        } else {
          let isEnd = false
          let nextPosIndex = this.inputClassHour - 1
          let nextTime = null
          while (!isEnd) {
            if (this.desc.position + nextPosIndex > this.times.length) {
              isEnd = false
              nextTime = 0
            }
            const nextTimePos = this.times.find(time => time.position === this.desc.position + nextPosIndex)
            if (nextTimePos?.type === 'kbm') {
              nextTime = nextTimePos.endAt
              isEnd = true
            }
            nextPosIndex++
          }

          return nextTime
        }
      }

      return 0
    },
    endAtEdit() {
      if (this.desc.jamMulai) {
        if (this.inputClassHourEdit === 1 || this.inputStartEdit === this.times.length) {
          return {
            position: this.inputStartEdit,
            value: this.desc.jamSelesai,
          }
        } else {
          let isEnd = false
          let nextPosIndex = this.inputClassHourEdit - 1
          let nextTime = null
          while (!isEnd) {
            if (Number(this.inputStartEdit) + nextPosIndex > this.times.length) {
              isEnd = true
              nextTime = { position: null, value: null }
            }
            const nextTimePos = this.times.find(time => time.position === Number(this.inputStartEdit) + nextPosIndex)
            // console.log(nextTimePos, nextPosIndex, 'tes', this.inputStartEdit, this.inputClassHourEdit)
            if (nextTimePos?.type === 'kbm') {
              nextTime = nextTimePos
              isEnd = true
            }
            nextPosIndex++
          }
          return {
            position: nextTime.position,
            value: nextTime.endAt,
          }
        }
      }

      return {
        position: 0,
        value: 0,
      }
    },
    listIstirahat() {
      if (this.times.length) {
        return this.times.filter(time => time.type === 'istirahat')
      }
      return []
    },
  },
  watch: {
    modalVisible(newVal) {
      if (newVal) {
        // this.checkRepeated()
      }
    },
  },
  created() {
    // let countSlot = 0
    // for (let i = 0; i < this.slots.length; i++) {
    //   const slot = this.slots[i]
    //   if (slot.id === this.desc.id) {
    //     countSlot++
    //   }
    //   if (countSlot > 1) {
    //     if (Number(this.desc.position) === Number(slot.posisi)) {
    //       this.isRepeated = true
    //     }
    //     break
    //   }
    // }
  },
}
</script>
<style lang="scss">
.boxScheduleAdmin {
  .buttonSlot {
    font-size: 1rem !important;
  }

  .textButtonSlot:hover {
    text-decoration: underline;
  }

  .break {
    width: 100%;
    height: 100%;
    background-color: #d3d9e6;
    color: white;
    /* border-radius: 10px; */
    /* margin: -2.5px; */
    /* padding: 10px 16px; */
    /* box-shadow: 0px 0px 2px #00000029; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hoverSlot {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-color: #d3d9e6;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .boxSlot {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 16px;
    cursor: pointer;
    box-shadow: 0px 0px 2px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s;
  }

  .boxSlot:hover {
    transform: translate(0, -5px);
    box-shadow: 0px 0px 20px #0419301a;
    transition: all 0.5s;
  }

  .animationPlus {
    animation: animationFrames 1s ease 0s 1 normal forwards running;
    transform-origin: 50% 50%;
  }
  @keyframes animationFrames {
    0% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0px);
    }
  }
}
</style>
